import React from "react";
import CheckBoxDropDownSelector from "../MktActionsFilterBarComps/CheckBoxDropDownSelector";

function DiseaseFilterMultiple({ diseaseList, filterChangeHandler, curFilters }) {
    
    // Extract selected diseases from curFilters
    const curDiseaseFilterItems = curFilters
        .filter((item) => item.field === "disease")
        .flatMap((item) => Array.isArray(item.value) ? item.value : [item.value]);

    // Map disease labels to their selected states
    const diseasesObj = diseaseList.reduce((obj, item) => {
        obj[item.label] = curDiseaseFilterItems.includes(item.value);
        return obj;
    }, {});

    // Check if all diseases are selected
    const allDiseasesSelected = 
        diseaseList.length > 0 &&
        curDiseaseFilterItems.length === diseaseList.length &&
        diseaseList.every((disease) => curDiseaseFilterItems.includes(disease.value));

    // handle both individual selections and "Select All"
    const handleSelect = (event) => {
        const selectedValue = event.target.value;
        const updatedSelection =
            selectedValue === "select-all"
                ? allDiseasesSelected
                    ? [] // Deselect all
                    : diseaseList.map((disease) => disease.value) // Select all
                : curDiseaseFilterItems.includes(selectedValue)
                    ? curDiseaseFilterItems.filter((value) => value !== selectedValue) // Remove selected
                    : [...curDiseaseFilterItems, selectedValue]; // Add selected

        filterChangeHandler({
            field: "disease",
            value: updatedSelection.length === diseaseList.length ? "all" : updatedSelection, // Update to "all" if all selected
        });
    };

    // Generate label string for selected diseases
    const labelString =
        allDiseasesSelected
            ? "All Diseases Selected"
            : curDiseaseFilterItems.length === 0
                ? "Select Diseases"
                : curDiseaseFilterItems.length <= 3
                    ? curDiseaseFilterItems.join(", ") // List diseases if <= 3
                    : `${curDiseaseFilterItems.length} Selected`; // Show count if > 3

    return (
        <CheckBoxDropDownSelector
            key={allDiseasesSelected} 
            keepOpen={true}
            options={diseasesObj}
            selected={curDiseaseFilterItems}
            displayOptionHandler={handleSelect}
            isAllSelected={allDiseasesSelected}
            labelString={labelString}
            selectAll={true}
            className="toolbar-standard-dropdown"
            name="disease"
        />
    );
}

export default DiseaseFilterMultiple;
