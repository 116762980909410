import React from "react";
import CheckBoxDropDownSelector from "../MktActionsFilterBarComps/CheckBoxDropDownSelector";

function CountryFilterMultiple({ countryList, filterChangeHandler, curFilters }) {

    // Extract selected countries from curFilters
    const curCountryFilterItems = curFilters
        .filter((item) => item.field === "country")
        .flatMap((item) => Array.isArray(item.value) ? item.value : [item.value]);

    // Map country labels to their selected states
    const countriesObj = countryList.reduce((obj, item) => {
        obj[item.label] = curCountryFilterItems.includes(item.value);
        return obj;
    }, {});

    // Check if all countries are selected
    const allCountriesSelected = 
        countryList.length > 0 &&
        curCountryFilterItems.length === countryList.length &&
        countryList.every((country) => curCountryFilterItems.includes(country.value));

    // Handle both individual selections and "Select All"
    const handleSelect = (event) => {
        const selectedValue = event.target.value;
        const updatedSelection =
            selectedValue === "select-all"
                ? allCountriesSelected
                    ? [] // Deselect all
                    : countryList.map((country) => country.value) // Select all
                : curCountryFilterItems.includes(selectedValue)
                    ? curCountryFilterItems.filter((value) => value !== selectedValue) // Remove selected
                    : [...curCountryFilterItems, selectedValue]; // Add selected

        filterChangeHandler({
            field: "country",
            value: updatedSelection.length === countryList.length ? "all" : updatedSelection, // Update to "all" if all selected
        });
    };

    // Generate label string for selected countries
    const labelString =
        allCountriesSelected
            ? "All Countries Selected"
            : curCountryFilterItems.length === 0
                ? "Select Countries"
                : curCountryFilterItems.length <= 3
                    ? curCountryFilterItems.join(", ") // List countries if <= 3
                    : `${curCountryFilterItems.length} Selected`; // Show count if > 3

    return (
        <CheckBoxDropDownSelector
            key={allCountriesSelected} 
            keepOpen={true}
            options={countriesObj}
            selected={curCountryFilterItems}
            displayOptionHandler={handleSelect}
            isAllSelected={allCountriesSelected}
            labelString={labelString}
            selectAll={true}
            className="toolbar-standard-dropdown"
            name="country"
        />
    );
}

export default CountryFilterMultiple;
