import React from 'react';
import CheckBoxDropDownSelector from '../MktActionsFilterBarComps/CheckBoxDropDownSelector';

// TODO: Update for multi-year selection when back end supports it.
// Currently supports SINGLE year filtering.

function YearFilterMultiple({ yearList, filterChangeHandler, curFilters }) {

    // Extract the currently selected years as an array of values
    const curYearFilterItems = curFilters
        .filter((item) => item.field === 'date_published') // Match the correct field
        .flatMap((item) => item.value); // Extract values

    // Map the available years to an object with their selection status
    const yearsObj = yearList.reduce((obj, item) => {
        obj[item.label] = curYearFilterItems.includes(item.label); // Mark selected years
        return obj;
    }, {});

    const handleYearSelection = (event) => {
        const selectedYear = event.target.value;

        // Toggle the selected year in the current selection
        const updatedYears = curYearFilterItems.includes(selectedYear)
            ? curYearFilterItems.filter((y) => y !== selectedYear) // Remove the year
            : [selectedYear]; // Replace with the new year

        // Update filters, passing null if no years are selected
        filterChangeHandler({
            field: 'date_published',
            value: updatedYears.length > 0 ? updatedYears : null,
        });
    };


    const yearLabelString = curYearFilterItems.length === yearList.length
    ? "All Years Selected"
    : curYearFilterItems.length > 0
        ? curYearFilterItems.length <= 3
            ? curYearFilterItems.join(", ") // List years if 3 or fewer
            : `${curYearFilterItems.length} Selected` // Show count if more than 3
        : "Year Published";



    return (
        <CheckBoxDropDownSelector
            keepOpen={true}
            options={yearsObj} 
            selected={curYearFilterItems} 
            displayOptionHandler={handleYearSelection} 
            labelString={yearLabelString}
            selectAll={false} // TODO: update for multi-select
            className="toolbar-standard-dropdown"
            name="year"
        />
    );
}

export default YearFilterMultiple;
