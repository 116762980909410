import React, { useEffect, useState, useRef } from 'react';
import { Dropdown } from 'react-bootstrap';
import { ChevronDown, ChevronUp } from 'react-bootstrap-icons';


function CheckBoxDropDownSelector({options, selected, displayOptionHandler, labelString, selectAll=false, className=null, name=null, keepOpen=false, disabled=false, isSearchable=false, handleSearchTerm, isAllSelected=null}) {

  //options is expected to be an obj - {"name of label1": true \ false} - note that you'll have to use "" if you want spaces in your option name

  const [ show, setShow ] = useState(false);
  const optionsList = Object.keys(options);  

  let menuRef = useRef()
  
  const closeMenuHandler = (e) => {
    setShow(!show)
    displayOptionHandler(e)
  }
  useEffect(() => {
    let handler = (e) => {
      if(e.target && menuRef && menuRef.current && !menuRef.current.contains(e.target ? e.target : '')) {
        setShow(false);
      }
    }
    document.addEventListener('mousedown', handler)
  },[keepOpen, menuRef]);

  return (
    <>
      <Dropdown ref={menuRef} className={className} align="end" autoClose={'outside'} show={disabled === true ? false : show}>
        <Dropdown.Toggle
          variant="light"
          align="end"
          className="dropdown-text-left"
          id="multiSelectDropdown"
          onClick={(e) => setShow(!show)}
        >
          {labelString}
          { show && !disabled ? <ChevronUp className='svg-16'/> : <ChevronDown className='svg-16'/> }
        </Dropdown.Toggle>

        <Dropdown.Menu show={show} align="end" className="dropdown-menu" id="dropdown-menu">
          {
            isSearchable === true ?
              <input
                autoFocus
                className="mx-3 my-2 w-auto"
                placeholder="Search..."
                onChange={(e) => handleSearchTerm(e.target.value)}
              />
            : ''
          }
          {
            selectAll === true ?
              <div
                key={'select-all'}
                aria-labelledby="multiSelectDropdown"
                className="dropdown-item"
                >
                <input
                  type="checkbox"
                  className="checkbox"
                  name={name || 'dropdown-checks'}
                  id={'select-all'}
                  value={'select-all'}
                  options={options}
                  checked={isAllSelected ?? JSON.stringify(options) === JSON.stringify(selected)}
                  onClick={(event) => {
                    keepOpen === true ? displayOptionHandler(event) : closeMenuHandler(event)
                  }}
                />
                <label htmlFor={'select-all'}>{'Select all'}</label>
              </div>
            : ''
          }
          
          {
            optionsList.map((option) => (
              <div
                key={option}
                aria-labelledby="multiSelectDropdown"
                className="dropdown-item"
                >
                <input
                  type="checkbox"
                  className="checkbox"
                  name={name || 'dropdown-checks'}
                  id={option}
                  value={option}
                  options={options}
                  checked={options[option] === true}
                  onClick={(event) => {
                    keepOpen === true ? displayOptionHandler(event) : closeMenuHandler(event)
                  }}
                />
                <label htmlFor={option}>{option}</label>
              </div>
            ))
          }
        </Dropdown.Menu>
      </Dropdown>
    </>
  );
    
}

export default CheckBoxDropDownSelector

