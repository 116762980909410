import React, { useState, useEffect } from "react";
import { useLocation, useHistory } from "react-router-dom";
import { Row, Col, Tab, Nav } from "react-bootstrap";
import { useMsal } from "@azure/msal-react";

import utils from "../../utils";
import filterUtils from "../../filterUtils";
import constants from "../../constants";

import BreadCrumbBar from "../Layout/Nav/BreadCrumbBar";
import CENewsToElasticAdapter from "./CENewsToElasticAdapter";
import CEFilterBar from "./CEFilterBar";
import PublicationsList from "./PublicationsList";
import ircUtils from "../IRC/ircUtils";

function CENewsApp({ tenantSetting, preview = false }) {
    // States for fetched data and applied filters
    const [diseaseList, setDiseaseList] = useState([]);
    const [countryList, setCountryList] = useState([]);
    const [APIKey, setAPIKey] = useState(null);
    const [filters, setFilters] = useState([]);

    const location = useLocation();
    const history = useHistory();
    const { instance, accounts } = useMsal();

    const tenantID = utils.getForceTenantValue() || tenantSetting?.tenant?.fields?.aDGroupID;

    if (tenantID && !utils.getForceTenantValue()) {
        utils.setForceTenantValue(tenantID);
    }

    // Track application view for analytics
    const trackCall = async () => {
        const token = await utils.getAccessToken(instance, accounts[0], { scopes: ["User.Read"] });
        if (!preview) {
            utils.trackAppView("ce-news", tenantID, token);
        }
    };

    // Fetch API key securely
    const getAPIKey = async () => {
        const token = await utils.getAccessToken(instance, accounts[0], { scopes: ["User.Read"] });
        const response = await fetch(process.env.REACT_APP_GVMM_API_KEY_URL, {
            headers: { Authorization: `Bearer ${token.accessToken}` },
        });
        const { value } = await response.json();
        setAPIKey(value);
        return value;
    };

    // Fetch country list
    const fetchCountries = async () => {
            const apiKey = await getAPIKey();
            const response = await utils.getData("get_ce_countries", apiKey, "");
            // Filter and format the response
            setCountryList(
                response
                    .filter((country) => country.trim() !== "") // Ensure no empty or invalid entries
                    .map((country) => ({
                        label: country,
                        value: country,
                    }))
            );
    };
    
    

    // Fetch disease list
    const fetchDiseases = async () => {
        const apiKey = await getAPIKey();
        const response = await utils.getData("get_ce_domains", apiKey, "");
        setDiseaseList(
            response
                .filter((disease) => disease.trim() !== "")
                .map((disease) => ({
                    label: disease,
                    value: disease,
                }))
        );
    };

    // Generate a list of years (current year back 10 years)
    const generateYearList = () => {
        const currentYear = new Date().getFullYear();
        return Array.from({ length: 10 }, (_, i) => ({
            label: `${currentYear - i}`,
            value: `${currentYear - i}`,
        }));
    };

    // Map fields to their corresponding lists
    const fieldToListMap = {
        disease: () => diseaseList.map((d) => d.value),
        country: () => countryList.map((c) => c.value),
        date_published: () => generateYearList().map((y) => y.value),
    };

    // Normalize filter values (handles "all" and subsets)
    const normalizeFilterValue = (value, list) => {
        if (value === "all") return "all";
        if (Array.isArray(value)) {
            const isAllSelected = value.length === list.length && list.every((item) => value.includes(item));
            return isAllSelected ? "all" : value;
        }
        return value ? [value] : [];
    };

    // Update filters array with new filter
    const setNewFilters = (prevFilters, newFilter) => {
        const updatedFilters = [...prevFilters];
        const index = updatedFilters.findIndex((filter) => filter.field.toLowerCase() === newFilter.field.toLowerCase());

        if (index > -1) {
            if (!newFilter.value || (Array.isArray(newFilter.value) && newFilter.value.length === 0)) {
                updatedFilters.splice(index, 1); // Remove filter if empty
            } else {
                updatedFilters[index] = newFilter; // Update existing filter
            }
        } else {
            updatedFilters.push(newFilter); // Add new filter
        }
        return updatedFilters;
    };

    // Handle filter changes
    const filterChanged = (newFilter) => {
        const fieldList = fieldToListMap[newFilter.field]?.() || [];
        if (!fieldList.length) {
            console.error(`Field list for ${newFilter.field} is undefined or empty`);
            return;
        }

        const normalizedValue = normalizeFilterValue(newFilter.value, fieldList);

        const updatedFilters = setNewFilters(filters, {
            field: newFilter.field,
            value: normalizedValue === "all" ? fieldList : normalizedValue,
            displayValue: normalizedValue,
        });

        setFilters(updatedFilters);

        const params = new URLSearchParams(location.search);
        params.delete(newFilter.field);
        if (normalizedValue === "all") {
            params.set(newFilter.field, "all");
        } else if (Array.isArray(normalizedValue)) {
            normalizedValue.forEach((value) => params.append(newFilter.field, value));
        }
        history.push({ search: params.toString() });
    };

    // Sync filters with URL query parameters
    useEffect(() => {
        if (!diseaseList.length || !countryList.length) return;

        const queryParams = new URLSearchParams(location.search);
        const newFilters = [];

        Object.keys(fieldToListMap).forEach((field) => {
            if (queryParams.has(field)) {
                let values = queryParams.getAll(field);
                if (values.includes("all")) values = fieldToListMap[field]?.();
                newFilters.push({ field, value: values });
            }
        });

        const mergedFilters = filters.filter(
            (filter) => !newFilters.some((nf) => nf.field === filter.field)
        );

        const updatedFilters = [...mergedFilters, ...newFilters];
        setFilters(updatedFilters);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location.search, diseaseList, countryList]);



    // Fetch initial data
    useEffect(() => {
        if (!APIKey) {
            fetchCountries();
            fetchDiseases();
        }
        if (process.env.REACT_APP_ENV === "PROD") {
            trackCall();
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [APIKey]);

    return (
        <>
            <BreadCrumbBar appName={constants.APPS_BY_APPTYPE.CE_NEWS} />
            <h1 className="h1 m-b-start-08 m-b-end-08">{constants.APPS_BY_NAME.CE_NEWS}</h1>
            <Tab.Container id="ce-news-tabs" defaultActiveKey="news">
                <Nav variant="tabs" className="mb-3">
                    <Nav.Item key="news">
                        <Nav.Link eventKey="news">News</Nav.Link>
                    </Nav.Item>
                    <Nav.Item key="publications">
                        <Nav.Link eventKey="publications">Publications</Nav.Link>
                    </Nav.Item>
                </Nav>
                <Tab.Content>
                    <Tab.Pane eventKey="news">
                        <Row>
                            <Col lg={9} className="search-content">
                                <CENewsToElasticAdapter
                                    inputArray={filters}
                                    paginationsize={25}
                                    formatForNewsList={ircUtils.formatForNewsList}
                                    filterChanged={filterChanged}
                                />
                            </Col>
                            <Col lg={3} className="uni-filter-bar m-b-start">
                                <CEFilterBar
                                    onFilterChanged={filterChanged}
                                    countryList={countryList}
                                    diseaseList={diseaseList}
                                    yearList={generateYearList()}
                                    curFilters={filters}
                                    getFilterTitle={(field) => filterUtils.findFilterTitle(field, filters, countryList)}
                                />
                            </Col>
                        </Row>
                    </Tab.Pane>
                    <Tab.Pane eventKey="publications">
                        <Row>
                            <Col lg={9}>
                                <PublicationsList titleOfList="" listName="cepublicationlist" />
                            </Col>
                        </Row>
                    </Tab.Pane>
                </Tab.Content>
            </Tab.Container>
        </>
    );
}

export default CENewsApp;
